var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('client-only',[_c('v-app',{attrs:{"dark":""}},[_c('v-app-bar',{attrs:{"id":"operation-manual-app-bar","fixed":""}},[_c('v-app-bar-nav-icon',{staticClass:"mobile-hamburger-menu-icon mr-2",on:{"click":function($event){return _vm.$nuxt.$emit('toggleSideBarDisplay')}}}),_vm._v(" "),_c('n-link',{attrs:{"to":"/support"},scopedSlots:_vm._u([{key:"default",fn:function({ navigate }){return [_c('v-card',{staticStyle:{"background-color":"rgba(39, 39, 39, 0.8) !important"},attrs:{"flat":"","role":"link","ripple":false},on:{"click":function($event){_vm.sliderShow = true}}},[_c('span',{staticClass:"d-flex operation-manual-header__title",staticStyle:{"align-self":"center","cursor":"pointer"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return navigate.apply(null, arguments)}}},[_vm._v("\n            .one Inspection Support\n          ")])])]}}])}),_vm._v(" "),_c('v-tabs',{staticClass:"tabs-class",staticStyle:{"background":"black"},attrs:{"grow":"","hide-slider":_vm.sliderShow},model:{value:(_vm.selectTab),callback:function ($$v) {_vm.selectTab=$$v},expression:"selectTab"}},[_c('v-tab',{staticClass:"d-flex flex-column",on:{"click":_vm.tabClick}},[_c('n-link',{staticStyle:{"color":"#1976d2"},attrs:{"to":"/support/started","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ navigate }){return [_c('v-btn',{staticClass:"operation-manual-header tabs-btn",attrs:{"role":"link","depressed":"","tile":""},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return navigate.apply(null, arguments)}}},[_c('span',{style:(_vm.sliderShow === false && _vm.selectTab === _vm.TAG_START
                    ? 'color: #1976d2'
                    : 'color: white')},[_vm._v("\n                入門ガイド\n              ")])])]}}])})],1),_vm._v(" "),_c('v-tab',{staticClass:"d-flex flex-column",on:{"click":_vm.tabClick}},[_c('n-link',{staticStyle:{"color":"#1976d2"},attrs:{"to":"/support/viewer","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ navigate }){return [_c('v-btn',{staticClass:"operation-manual-header tabs-btn",attrs:{"role":"link","tile":"","depressed":""},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return navigate.apply(null, arguments)}}},[_c('span',{style:(_vm.sliderShow === false && _vm.selectTab === _vm.TAG_VIEWER
                    ? 'color: #1976d2'
                    : 'color: white')},[_vm._v("\n                VIEWER\n              ")])])]}}])})],1),_vm._v(" "),_c('v-tab',{staticClass:"d-flex flex-column",on:{"click":_vm.tabClick}},[_c('n-link',{staticStyle:{"color":"#1976d2"},attrs:{"to":"/support/file","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ navigate }){return [_c('v-btn',{staticClass:"operation-manual-header tabs-btn",attrs:{"role":"link","depressed":"","tile":""},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return navigate.apply(null, arguments)}}},[_c('span',{style:(_vm.sliderShow === false && _vm.selectTab === _vm.TAG_FILE
                    ? 'color: #1976d2'
                    : 'color: white')},[_vm._v("\n                ファイル一覧\n              ")])])]}}])})],1),_vm._v(" "),_c('v-tab',{staticClass:"d-flex flex-column",on:{"click":_vm.tabClick}},[_c('n-link',{staticStyle:{"color":"#1976d2"},attrs:{"to":"/support/backend","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ navigate }){return [_c('v-btn',{staticClass:"operation-manual-header tabs-btn",attrs:{"role":"link","depressed":"","tile":""},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return navigate.apply(null, arguments)}}},[_c('span',{style:(_vm.sliderShow === false && _vm.selectTab === _vm.TAG_BACKEND
                    ? 'color: #1976d2'
                    : 'color: white')},[_vm._v("\n                管理機能\n              ")])])]}}])})],1),_vm._v(" "),_c('v-tab',{staticClass:"d-flex flex-column",on:{"click":_vm.tabClick}},[_c('n-link',{staticStyle:{"color":"#1976d2"},attrs:{"to":"/support/inspectionDrawing","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ navigate }){return [_c('v-btn',{staticClass:"operation-manual-header tabs-btn",attrs:{"role":"link","depressed":"","tile":""},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return navigate.apply(null, arguments)}}},[_c('span',{style:(_vm.sliderShow === false && _vm.selectTab === _vm.TAG_DRAWING
                    ? 'color: #1976d2'
                    : 'color: white')},[_vm._v("\n                検査図面\n              ")])])]}}])})],1),_vm._v(" "),_c('v-tab',{staticClass:"d-flex flex-column",on:{"click":_vm.tabClick}},[_c('n-link',{staticStyle:{"color":"#1976d2"},attrs:{"to":"/support/inspectionRegistry","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ navigate }){return [_c('v-btn',{staticClass:"operation-manual-header tabs-btn",attrs:{"role":"link","depressed":"","tile":""},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return navigate.apply(null, arguments)}}},[_c('span',{style:(_vm.sliderShow === false && _vm.selectTab === _vm.TAG_REGISTRY
                    ? 'color: #1976d2'
                    : 'color: white')},[_vm._v("\n                検査登録\n              ")])])]}}])})],1)],1)],1),_vm._v(" "),_c('v-main',{staticClass:"header-margin-top"},[_c('div',{attrs:{"fluid":""}},[_c('nuxt')],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }