import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4cb959d5 = () => interopDefault(import('../pages/admin/index.vue' /* webpackChunkName: "pages/admin/index" */))
const _356edec0 = () => interopDefault(import('../pages/displayPdf/index.vue' /* webpackChunkName: "pages/displayPdf/index" */))
const _54845f78 = () => interopDefault(import('../pages/inspection/index.vue' /* webpackChunkName: "pages/inspection/index" */))
const _2a5af028 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _86e103d2 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _322950d6 = () => interopDefault(import('../pages/support/index.vue' /* webpackChunkName: "pages/support/index" */))
const _5a354432 = () => interopDefault(import('../pages/admin/information/index.vue' /* webpackChunkName: "pages/admin/information/index" */))
const _93f78978 = () => interopDefault(import('../pages/admin/inspection/index.vue' /* webpackChunkName: "pages/admin/inspection/index" */))
const _63c91d11 = () => interopDefault(import('../pages/admin/output/index.vue' /* webpackChunkName: "pages/admin/output/index" */))
const _516ba87c = () => interopDefault(import('../pages/admin/projectsettings/index.vue' /* webpackChunkName: "pages/admin/projectsettings/index" */))
const _074e0585 = () => interopDefault(import('../pages/admin/transferData/index.vue' /* webpackChunkName: "pages/admin/transferData/index" */))
const _40099e04 = () => interopDefault(import('../pages/admin/usersettings/index.vue' /* webpackChunkName: "pages/admin/usersettings/index" */))
const _31d2c24c = () => interopDefault(import('../pages/support/backend/index.vue' /* webpackChunkName: "pages/support/backend/index" */))
const _43270168 = () => interopDefault(import('../pages/support/file/index.vue' /* webpackChunkName: "pages/support/file/index" */))
const _5e9891e0 = () => interopDefault(import('../pages/support/inspectionDrawing/index.vue' /* webpackChunkName: "pages/support/inspectionDrawing/index" */))
const _d7179ebe = () => interopDefault(import('../pages/support/inspectionRegistry/index.vue' /* webpackChunkName: "pages/support/inspectionRegistry/index" */))
const _2dfa1487 = () => interopDefault(import('../pages/support/started/index.vue' /* webpackChunkName: "pages/support/started/index" */))
const _2c18df22 = () => interopDefault(import('../pages/support/viewer/index.vue' /* webpackChunkName: "pages/support/viewer/index" */))
const _212ae4d5 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _987ee8b4 = () => interopDefault(import('../pages/bim/viewer/_urn/version/_version.vue' /* webpackChunkName: "pages/bim/viewer/_urn/version/_version" */))
const _46a6b3ca = () => interopDefault(import('../pages/bim/viewer/_urn/version/_version/guid/_guid.vue' /* webpackChunkName: "pages/bim/viewer/_urn/version/_version/guid/_guid" */))
const _22801850 = () => interopDefault(import('../pages/hub/_hubId.vue' /* webpackChunkName: "pages/hub/_hubId" */))
const _6b8ca812 = () => interopDefault(import('../pages/hub/_hubId/project/_projectId.vue' /* webpackChunkName: "pages/hub/_hubId/project/_projectId" */))
const _6ad93728 = () => interopDefault(import('../pages/hub/_hubId/project/_projectId/folder/_folderUrn.vue' /* webpackChunkName: "pages/hub/_hubId/project/_projectId/folder/_folderUrn" */))
const _7d6b78d1 = () => interopDefault(import('../pages/hub/_hubId/project/_projectId/folder/_folderUrn/_folderName.vue' /* webpackChunkName: "pages/hub/_hubId/project/_projectId/folder/_folderUrn/_folderName" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/admin",
    component: _4cb959d5,
    name: "admin"
  }, {
    path: "/displayPdf",
    component: _356edec0,
    name: "displayPdf"
  }, {
    path: "/inspection",
    component: _54845f78,
    name: "inspection"
  }, {
    path: "/login",
    component: _2a5af028,
    name: "login"
  }, {
    path: "/logout",
    component: _86e103d2,
    name: "logout"
  }, {
    path: "/support",
    component: _322950d6,
    name: "support"
  }, {
    path: "/admin/information",
    component: _5a354432,
    name: "admin-information"
  }, {
    path: "/admin/inspection",
    component: _93f78978,
    name: "admin-inspection"
  }, {
    path: "/admin/output",
    component: _63c91d11,
    name: "admin-output"
  }, {
    path: "/admin/projectsettings",
    component: _516ba87c,
    name: "admin-projectsettings"
  }, {
    path: "/admin/transferData",
    component: _074e0585,
    name: "admin-transferData"
  }, {
    path: "/admin/usersettings",
    component: _40099e04,
    name: "admin-usersettings"
  }, {
    path: "/support/backend",
    component: _31d2c24c,
    name: "support-backend"
  }, {
    path: "/support/file",
    component: _43270168,
    name: "support-file"
  }, {
    path: "/support/inspectionDrawing",
    component: _5e9891e0,
    name: "support-inspectionDrawing"
  }, {
    path: "/support/inspectionRegistry",
    component: _d7179ebe,
    name: "support-inspectionRegistry"
  }, {
    path: "/support/started",
    component: _2dfa1487,
    name: "support-started"
  }, {
    path: "/support/viewer",
    component: _2c18df22,
    name: "support-viewer"
  }, {
    path: "/",
    component: _212ae4d5,
    name: "index"
  }, {
    path: "/bim/viewer/:urn?/version/:version?",
    component: _987ee8b4,
    name: "bim-viewer-urn-version-version",
    children: [{
      path: "guid/:guid?",
      component: _46a6b3ca,
      name: "bim-viewer-urn-version-version-guid-guid"
    }]
  }, {
    path: "/hub/:hubId?",
    component: _22801850,
    name: "hub-hubId",
    children: [{
      path: "project/:projectId?",
      component: _6b8ca812,
      name: "hub-hubId-project-projectId",
      children: [{
        path: "folder/:folderUrn?",
        component: _6ad93728,
        name: "hub-hubId-project-projectId-folder-folderUrn",
        children: [{
          path: ":folderName?",
          component: _7d6b78d1,
          name: "hub-hubId-project-projectId-folder-folderUrn-folderName"
        }]
      }]
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
